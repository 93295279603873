import $ from 'jquery';

/**
 * Binds the actions related to seller reviews.
 */
export default function bindSellerReviews() {
    const $el = $('.js-seller-reviews');

    //
    // Seller Reviews - Details Actions
    //

    $el.find('.js-view-all-reviews-link').on('click', ({ currentTarget }) => {
        const $remainingCards = $el.find('.js-seller-reviews-cards-remaining');
        $remainingCards.toggleClass('hidden');
        $(currentTarget).text($remainingCards.hasClass('hidden') ? 'Read More Reviews' : 'View Less');
    });

    //
    // Seller Reviews - Card Actions
    //

    const $reviewsCard = $el.find('.js-seller-reviews-card');
    $reviewsCard.find('.js-reviews-card-img-btn').on('click', () => {
        // TODO (CHAIR-16074): Open lightbox with scrollable images and reviews
    });

    $reviewsCard.find('.js-reviews-card-content').on('click', ({ currentTarget }) => {
        $(currentTarget).toggleClass('truncate');
    });
}
